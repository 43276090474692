import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
const baseURL = require("../../../framework/src/config.js").baseURL
export const configJSON = require("./config");

type AnyStringKeyObject = {
    [key: string]: any;
};

interface CatalogueEntry {
    id: string;
    type: string;
    attributes: {
        selling_price: number;
    };
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    sidebarOpen: boolean;
    uploadedImages: any;
    uploadedVideos: any;
    addColorDialog: boolean;
    colour: any;
    addColour: string;
    colourErrorMessage: string;
    addSizeDialog: boolean;
    size: any;
    addSize: any;
    sizeErrorMessage: string;
    editColour: boolean;
    editSize: boolean;
    isExpanded: boolean;
    productSuccessDialog: boolean;
    pageToShow: string;
    selectedColour: any;
    selectedColourName: string;
    selectedSize: any;
    variantPrice: string;
    variantStock: string;
    colourSelctionError: string;
    uploadImageError: string;
    uploadVideoError: string;
    sizeSelectionError: string;
    varianrPriceError: string;
    variantStockError: string;
    sameValueForAll: boolean;
    catalogueID: any;
    checkAddorEditVariant: boolean;
    variantID: string;
    variantData: AnyStringKeyObject;
    filteredVariantData: AnyStringKeyObject;
    errorModal: boolean;
    errorMessage: string;
    imageRemoveArray: Array<any>;
    videoRemoveArray: Array<any>;
    selectedSizeData: Array<any>;
    productDetails:CatalogueEntry 
    storetypeData: string | null
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddProductVariantsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    inputRef: React.RefObject<HTMLInputElement>;
    videoInputRef: React.RefObject<HTMLInputElement>;
    getColoursDataApiCallId: string = '';
    getSizesDataApiCallId: string = '';
    createNewColourApiCallId: string = '';
    createNewSizeApiCallId: string = '';
    addProductVariantApiCallId: string = '';
    removeSelectedColourApiCallId: string = '';
    removeSelectedSizeApiCallId: string = '';
    getVariantDataApiCallId: string = ''
    existingFileIds: string[] = [];
    existingVideoFileIds: string[] = [];
    imageDeleteId: string = "";
    videoDeleteId: string = "";
    editProductVariantApiCallId: string = ""
    getProductDetailsId:string =""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            sidebarOpen: true,
            uploadedImages: [],
            uploadedVideos: [],
            addColorDialog: false,
            colour: [],
            addColour: '',
            colourErrorMessage: '',
            addSizeDialog: false,
            size: [],
            addSize: '',
            sizeErrorMessage: '',
            editColour: false,
            editSize: false,
            isExpanded: true,
            productSuccessDialog: false,
            pageToShow: 'AddVariant',
            selectedColour: '',
            selectedColourName: '',
            selectedSize: '',
            variantPrice: '',
            variantStock: '',
            colourSelctionError: '',
            uploadImageError: '',
            uploadVideoError: '',
            sizeSelectionError: '',
            varianrPriceError: '',
            variantStockError: '',
            sameValueForAll: false,
            catalogueID: '',
            checkAddorEditVariant: false,
            variantID: '',
            variantData: {},
            filteredVariantData: {},
            errorModal: false,
            errorMessage: '',
            imageRemoveArray: [],
            videoRemoveArray: [],
            selectedSizeData: [],
            productDetails:{
                id: "",
                type: "",
                attributes: {
                    selling_price: 0
                }
            },
            storetypeData:""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.inputRef = React.createRef()
        this.videoInputRef = React.createRef()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getColoursDataApiCallId) {
                this.setState({ loading: false })
                if (responseJson.data) {
                    this.setState({
                        colour: responseJson.data
                    })
                }
            } else if (apiRequestCallId === this.getSizesDataApiCallId) {
                this.setState({ loading: false })
                if (responseJson.data) {
                    this.setState({
                        size: responseJson.data
                    })
                }
            } else if (apiRequestCallId === this.createNewColourApiCallId) {
                this.setState({ loading: false })
                if (responseJson.data) {
                    this.getColoursData();
                    this.setState({
                        addColorDialog: false
                    })
                }
            } else if (apiRequestCallId === this.createNewSizeApiCallId) {
                this.setState({ loading: false })
                if (responseJson.data) {
                    this.getSizesData();
                    this.setState({
                        addSizeDialog: false
                    })
                }
            } else if (apiRequestCallId === this.removeSelectedColourApiCallId) {                
                if (responseJson.message) {
                    this.getColoursData();
                    this.setState({ errorModal: true, errorMessage: responseJson.message });
                } else{
                    this.getColoursData();
                    this.setState({ errorModal: true, errorMessage: "Color Successfully deleted" });
                }
            } else if (apiRequestCallId === this.removeSelectedSizeApiCallId) {
                if (responseJson.message) {
                    this.getSizesData();
                    this.setState({ errorModal: true, errorMessage: responseJson.message });
                } else{
                    this.getSizesData();
                    this.setState({ errorModal: true, errorMessage: "Size Successfully deleted" });
                }
            } else if (apiRequestCallId === this.addProductVariantApiCallId) {
                this.setState({ loading: false })
                if (responseJson.message) {
                    this.setState({
                        productSuccessDialog: true
                    })
                } else {
                    this.setState({ varianrPriceError: responseJson.errors })
                }
            }else if(apiRequestCallId === this.getProductDetailsId){
                    this.handleProductDetails(responseJson);
            }

            switch (apiRequestCallId) {
                case this.getVariantDataApiCallId:
                    this.setState({ loading: false })
                    this.setVariantData(responseJson);                    
                    break;
                case this.editProductVariantApiCallId:
                    this.checkchckEditVariant(responseJson);
                    break;
                case this.imageDeleteId:
                    this.checkUpdatedeteleImgVid(responseJson);
                    break;
                default:
                    break;
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getColoursData();
        this.getSizesData();
        let catalogueID = null;
        const catlogID = localStorage.getItem("catalogueID");
        const storeType = localStorage.getItem("storeType");
        catalogueID = await getStorageData("addVariantCatalogueID")
        
        if (catalogueID === null) {
            catalogueID = await getStorageData("editVariantCatalogueID");
            this.setState({ checkAddorEditVariant: true });
        }
        let variantId = null;
        variantId = await getStorageData("editVarientID");
        this.getProductDetails(catalogueID || catlogID)
        this.setState({ catalogueID: catalogueID || catlogID, variantID: variantId, storetypeData:storeType });
            this.getVariantData();
        // Customizable Area End
    }

    // Customizable Area Start
    setVariantData = (responseJson: any) => {                
        if (responseJson.data) {
            this.setState({
                variantData: responseJson.data.attributes,
            }, () => {
                let colorFilteredData;
                colorFilteredData = this.setAllFieldsData(this.state.variantID)                
                this.setState({ filteredVariantData: colorFilteredData },
                    () => {
                        if (this.state.filteredVariantData) {
                            this.setState({
                                selectedColour: this.state.filteredVariantData.catalogue_variant_color_id,
                                variantPrice: this.state.filteredVariantData.sizes_data[0]?.attributes?.price,
                                variantStock: this.state.filteredVariantData.sizes_data[0]?.attributes?.stock_qty,
                            })
                        }

                        const transformedData: any[] = this.state.filteredVariantData?.sizes_data.map((item:any) => ({
                            ...item,
                            attributes: item.attributes.catalogue_variant_size,
                            price: item.attributes.price,
                            stock_qty: item.attributes.stock_qty
                          }));

                          this.setState({
                            selectedSizeData: transformedData
                          })
                        if (this.state.filteredVariantData?.catalogue_variant_images) {
                            const existingImages = this.state.filteredVariantData.catalogue_variant_images?.map(
                                (image: any) => ({
                                    id: image.id,
                                    url: baseURL + image.url
                                })
                            );
                            const existingVideos = this.state.filteredVariantData.catalogue_variant_videos?.map(
                                (video: any) => ({
                                    id: video.id,
                                    url: baseURL + video.url
                                })
                            );
                            this.existingVideoFileIds = existingVideos?.map((video: any) => video.id);
                            this.existingFileIds = existingImages?.map((image: any) => image.id);
                            this.setState({ uploadedImages: existingImages || [],uploadedVideos: existingVideos || [] });
                        }
                    });
            })
        }
    }

    setAllFieldsData = (variantId: string) => {
        for (const variantKey in this.state.variantData.catalogue_variants) {
            const variant = this.state.variantData.catalogue_variants[variantKey];
            
            if (variant.catalogue_variant_color_id === Number(variantId)) {
                this.setState({ selectedColourName: variantKey })
                return variant;
            }
        }
        return null;
    }

    errorModalClose=()=>{
        this.setState({errorModal:false,errorMessage:''})
    }

    openAddColorDialog = () => {
        this.setState({
            addColorDialog: true,
            addColour: '',
            colourErrorMessage: ''
        })
    }

    checkchckEditVariant = (responseJson: any) => {
        if (responseJson.message) {
            if (this.state.uploadedImages.length !== this.existingFileIds.length || this.state.uploadedVideos.length !== this.existingVideoFileIds.length || this.state.imageRemoveArray.length > 0 || this.state.videoRemoveArray.length > 0) {
                this.handleDeleteImageVideo();
            } else {
                this.setState({
                    productSuccessDialog: true
                })
            }
        } else {
            this.setState({ varianrPriceError: responseJson.errors })
        }
    }

    closeAddColorDialogBox = () => {
        this.setState({
            addColorDialog: false
        })
    }

    openAddSizeDialog = () => {
        this.setState({
            addSizeDialog: true,
            addSize: '',
            sizeErrorMessage: ''
        })
    }

    closeAddSizeDialogBox = () => {
        this.setState({
            addSizeDialog: false
        })
    }

    closeSideDrawer = () => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        })
    }

    openProductSuccessDialogBox = () => {
        this.setState({
            productSuccessDialog: true
        })
    }

    closeProductSuccessDialogBox = () => {
        this.setState({
            productSuccessDialog: false
        }, () => {
            this.props.navigation.goBack();
            removeStorageData("addVariantCatalogueID");
            removeStorageData("editVariantCatalogueID");
            removeStorageData("editVarientID");
        })
    }

    handleRemoveImage = (index: number) => {
        const updatedImages = [...this.state.uploadedImages];
        const removedItem = updatedImages.splice(index, 1)[0];
        const idToBeDeleted = removedItem?.id;
        this.setState({ uploadedImages: updatedImages });

        this.setState((prevState) => ({
            imageRemoveArray: [...prevState.imageRemoveArray, idToBeDeleted],
        }));
    };

    handleAddImage = () => {
        this.inputRef?.current?.click();
    };

    handleEditColour = () => {
        this.setState({
            editColour: !this.state.editColour
        })
    }

    handleEditSize = () => {
        this.setState({
            editSize: !this.state.editSize
        })
    }

    handleSamePriceAndStock = () => {
        if(!this.state.sameValueForAll){
            const sellingPrice = this.state.productDetails.attributes.selling_price
            const updatedSizes = this.state.selectedSizeData.map(size => ({
                ...size,
                price: sellingPrice,
            }));
    
            this.setState({
                sameValueForAll: !this.state.sameValueForAll,
                selectedSizeData: updatedSizes
            });

        }else{
            this.setState({
                sameValueForAll: !this.state.sameValueForAll
            })
        }
       
    }

    handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const maxFiles = 6;
            if ((files.length + this.state.uploadedImages.length) > 6) {
                this.setState({errorModal:true,errorMessage: `You can only select up to ${maxFiles} files.`})
                return;
            }

            const validImages = await Promise.all(Array.from(files).map(async (file) => {
                const fileDimensions = await this.getImageDimension(file);


                if (file.size > 5 * 1024 * 1024) {
                    this.setState({errorModal:true,errorMessage: `Image "${file.name}" exceeds the maximum size of 5 MB.`})
                    return null;
                }

                return file;
            }));

            const filteredImages = validImages.filter((image) => image !== null);

            if (filteredImages.length > 0) {
                this.setState((prevState) => ({
                    uploadedImages: [...prevState.uploadedImages, ...filteredImages],
                    uploadImageError: ''
                }));

                if (this.inputRef.current) {
                    this.inputRef.current.value = "";
                }
            }
        }
    };

    getImageDimension = (file: File): Promise<{ width: number; height: number }> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        });
    };

    checkUpdatedeteleImgVid = (responseJson: any) => {
        if (responseJson.catalogue_variants) {
            this.setState({
                productSuccessDialog: true
            })
        } else {
            this.setState({ uploadVideoError: responseJson.error['catalogue_variant_videos.video'][0] })
        }
    }

    handleProductDetails =(responseJson: {data:CatalogueEntry}) => {
        if (responseJson.data) {
            this.setState({
                productDetails: responseJson.data
            })
        } 
    }

    isValidImageRatio = (dimensions: { width: number; height: number }): boolean => {
        const aspectRatio = dimensions.width / dimensions.height;
        return aspectRatio === 1 || aspectRatio === 3 / 4;
    };

    handleVideoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const maxFiles = 2;
            if ((files.length + this.state.uploadedVideos.length) > 2) {
                this.setState({errorModal:true,errorMessage: `You can only select up to ${maxFiles} files.`})
                return;
            }
            const selectedVideoNames = this.state.uploadedVideos.map((video: File) => video.name);
            const newVideos = Array.from(files).filter((file: any) => !selectedVideoNames.includes(file.name));
            if (newVideos.length !== files.length) {
                this.setState({errorModal:true,errorMessage: "You can't upload the same video twice."})
                return;
            }
            const validVideos = await this.validateVideos(Array.from(files));

            const filteredVideos = validVideos.filter((video) => video !== null);            
            if (filteredVideos.length > 0) {
                this.setState((prevState) => ({
                    uploadedVideos: [...prevState.uploadedVideos, ...filteredVideos],
                    uploadVideoError: ''
                }));
                if (this.videoInputRef.current) {
                    this.videoInputRef.current.value = "";
                }
            }
        }
    };

    validateVideos = async (files: File[]) => {
        return Promise.all(files.map(async (file: any) => {
            if (file.size > 10 * 1024 * 1024) {
                this.setState({ errorModal: true, errorMessage: `Video "${file.name}" exceeds the maximum size of 10 MB.` });
                return null;
            }
            if (file.type !== "video/mp4") {
                this.setState({ errorModal: true, errorMessage: `File "${file.name}" is not an MP4 video` });
                return null;
            }
            return file;
        }));
    };

    handleRemoveVideo = (index: number) => {
        const updatedVideos = [...this.state.uploadedVideos];
        const removedVideo = updatedVideos.splice(index, 1)[0];
        const idToBeDeleted = removedVideo?.id

        this.setState({
            uploadedVideos: updatedVideos,
        });

        this.setState((prevState) => ({
            videoRemoveArray: [...prevState.videoRemoveArray, idToBeDeleted],
        }));
    };

    handleAddVideo = (event: any) => {
        event?.preventDefault();
        this.videoInputRef?.current?.click();
    };

    handleColourChange = (event: any) => {
        const inputValue = event.target.value;
        const alphabeticInput = inputValue.replace(/[^a-zA-Z]/g, "");
        this.setState({
            addColour: alphabeticInput,
            colourErrorMessage: ''
        })
    }

    addColour = () => {
        const { addColour, colour } = this.state;
        if (!addColour) {
            this.setState({
                colourErrorMessage: 'Colour is required'
            });
        } else if (colour.some((colorObj: any) => colorObj.attributes.name.toLowerCase() === addColour.toLowerCase())) {
            this.setState({
                colourErrorMessage: 'Colour already existed'
            });
        } else {
            this.setState({
                colourErrorMessage: ''
            }, () => {
                this.createNewColour();
            });
        }
    }

    handleSizeChange = (event: any) => {
        this.setState({
            addSize: event.target.value,
            sizeErrorMessage: ''
        })
    }

    addSize = () => {
        const { addSize, size } = this.state;
        if (!addSize) {
            this.setState({
                sizeErrorMessage: 'Size is required'
            });
        } else if (size.some((sizeObj: any) => sizeObj.attributes.name.toLowerCase() === addSize.toLowerCase())) {
            this.setState({
                sizeErrorMessage: 'Size already existed'
            });
        } else {
            this.setState({
                sizeErrorMessage: ''
            }, () => {
                this.createNewSize();
            });
        }
    }

    handleIsExpand = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    goBackOnAddProduct = () => {
        this.props.navigation.goBack();
    }

    goBackOnAddVariant = () => {
        this.setState({
            pageToShow: 'AddVariant'
        })
    }

    validateColourSelection = () => {
        const { selectedColour } = this.state;
        this.setAllFieldsData(selectedColour)
        if (selectedColour === '') {
            this.setState({
                colourSelctionError: 'Please Select any one colour'
            });
            return false;
        } else {
            this.setState({
                colourSelctionError: ''
            });
            return true;
        }
    }

    validateSizeSelection = () => {
        const { selectedSizeData } = this.state;
        if (selectedSizeData.length === 0) {
            this.setState({
                sizeSelectionError: 'Please Select any one size'
            });
            return false;
        } else {
            this.setState({
                sizeSelectionError: ''
            });
            return true;
        }
    }

    validateImageUpload = () => {
        const { uploadedImages } = this.state;
        if (uploadedImages.length === 0) {
            this.setState({
                uploadImageError: 'Please upload atleast one image'
            });
            return false;
        } else {
            this.setState({
                uploadImageError: ''
            });
            return true;
        }
    }

  
    navigateToInfoPage = () => {
        let colorSelected = this.validateColourSelection();
        let sizeSelected = this.validateSizeSelection();
        let imageUploaded = this.validateImageUpload();
        
        if (colorSelected && sizeSelected && imageUploaded) {
            this.setState({
                pageToShow: 'VariantInfo'
            })
        }
    }

    handleSelectColour = (colour: any) => {
        this.setAllFieldsData(colour.attributes.id)
        this.setState({
            selectedColour: colour.attributes.id,
            colourSelctionError: ''
        })
    }

    handleSelectSize = (size: any) => {
        this.setState({
            selectedSize: size.attributes.id,
            sizeSelectionError: ''
        })
        const newSize = {
            ...size,
            price: '',
            stock_qty: '',
            priceError: '',
            stockError: ''
        }

        if (!this.state.selectedSizeData) {
            this.setState({
                selectedSizeData: [newSize],
            });
        } else {
            const isDuplicate = this.state.selectedSizeData.some((item) => item.attributes.id === Number(size.attributes.id));
            if (isDuplicate) {
                const updatedSizedata = this.state.selectedSizeData.filter((item) => item.attributes.id != size.attributes.id);
                this.setState({ selectedSizeData: updatedSizedata });
            } else {
                this.setState((prevState) => ({
                    selectedSizeData: [...prevState.selectedSizeData, newSize],
                }));
            }
        }
    }

    checkSizeStatus = (producArray: any, ID: any) => {
        if (Array.isArray(producArray) && producArray.length > 0) {
            let index = producArray.findIndex((item) => item && item.attributes && item.attributes.id === Number(ID));
            return index !== -1;
        }
        return false;
    }

    handleVariantPriceChange = (e: any, index: any) => {
        if (this.state.selectedSizeData?.length) {
            const newDataArray = [...this.state.selectedSizeData];
            newDataArray[index].price = e.target.value;
            this.setState({ selectedSizeData: newDataArray });
        }
    }

    handleVariantStockChange = (e: any, index: any) => {
        const newDataArray = [...this.state.selectedSizeData];
        newDataArray[index].stock_qty = e.target.value;
        this.setState({ selectedSizeData: newDataArray });
    }

    validateVariantPriceStock = () => {
        const { selectedSizeData } = this.state;        
        const errorData = selectedSizeData.map((item: any, index: any) => {
            return {
                ...item,
                priceError: item.price <= 0 ? 'Please enter valid price for variant' : '',
                stockError: item.stock_qty <= 0 ? 'Please enter valid stock quantity for variant' : ''
            }
        })
        const checkData = errorData.filter((item) => (item.priceError !== '' || item.stockError !== ''))
        this.setState({ selectedSizeData: errorData })
        if (checkData.length !== 0) {
            return false;
        } else {
            return true;
        }
    }

    handleAddProductVariant = () => {
        let validPrice = this.validateVariantPriceStock();

        if (validPrice) {
            if (this.state.filteredVariantData && this.state.checkAddorEditVariant) {
                this.EditProductVariant();
            } else {
                this.addProductVariant();
            }
        }
    }

    handleRemoveColour = (event: any, id: any) => {
        event.stopPropagation();
        this.removeSelectedColour(id);
    }

    handleRemoveSize = (event: any, id: any) => {
        event.stopPropagation();
        this.removeSelectedSize(id);
    }

    getColoursData = async () => {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json", token: await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getColoursDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_colors');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSizesData = async () => {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json", token: await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSizesDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_sizes');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createNewColour = async () => {
        const httpBody = {
            "name": this.state.addColour
        }
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createNewColourApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_colors');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createNewSize = async () => {
        const httpBody = {
            "name": this.state.addSize
        }
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createNewSizeApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_sizes');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeSelectedColour = async (id: any) => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeSelectedColourApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_colors/' + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeSelectedSize = async (id: any) => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeSelectedSizeApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants_sizes/' + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addProductVariant = async () => {
        this.setState({ loading: true });
        const { uploadedImages, uploadedVideos, catalogueID, selectedColour, selectedSize, variantPrice, variantStock } = this.state;

        const formData = new FormData()
        uploadedImages.forEach((image: File,) => {
            formData.append(`images[]`, image);
        });
        uploadedVideos.forEach((video: File,) => {
            formData.append(`videos[]`, video);
        });
        formData.append('catalogue_id', catalogueID);
        formData.append('catalogue_variant_color_id', selectedColour);
        this.state.selectedSizeData.forEach((item) => {
            formData.append('catalogue_variant_sizes[][id]', item.id);
            formData.append('catalogue_variant_sizes[][price]', item.price);
            formData.append('catalogue_variant_sizes[][stock_qty]', item.stock_qty);
        })

        const header = { "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addProductVariantApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.props.navigation.push("/InventoryManagement");
    }

    EditProductVariant = async () => {
        this.setState({ loading: true });
        const { catalogueID, variantID } = this.state;
        
        const ids1 = this.state.filteredVariantData.sizes_data.map((item: any) => item.id);
        const ids2 = this.state.selectedSizeData.map((item:any) => item.id);
        const alreadyPresentIds = ids1.filter((id: any) => ids2.includes(id));
        const newlyAddedIds = ids2.filter((id:any) => !ids1.includes(id));
        const removedIds = ids1.filter((id: any) => !ids2.includes(id));

        const alreadyPresentObjects = this.state.selectedSizeData
            .filter((item) => alreadyPresentIds.includes(item.id))
            .map((item) => ({
                id: Number(item.id),
                catalogue_variant_size_id: Number(item.attributes.id),
                price: parseFloat(item.price),
                stock_qty: parseInt(item.stock_qty)
            }));

        const newlyAddedObjects = this.state.selectedSizeData
            .filter((item) => newlyAddedIds.includes(item.id))
            .map((item) => ({
                catalogue_variant_size_id: Number(item.attributes.id),
                price: parseFloat(item.price),
                stock_qty: parseInt(item.stock_qty),
                new: true
            }));

        const removedObjects = this.state.filteredVariantData.sizes_data
            .filter((item: any) => removedIds.includes(item.id))
            .map((item: any) => ({
                id: Number(item.id),
                destroy: true
            }));

        const catalogue_variant_details = [...alreadyPresentObjects, ...newlyAddedObjects, ...removedObjects]

        const body = {
            "catalogue_id": Number(catalogueID),
            "catalogue_variant_color_id": Number(variantID),
            "catalogue_variant_details": catalogue_variant_details
        }

        const header = { "token": await getStorageData("singupLogin"), "Content-Type": 'application/json' };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editProductVariantApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_catalogue/catalogues_variants/update_variant');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.props.navigation.push("/InventoryManagement");
    }

    getVariantData = async () => {
        this.setState({ loading: true })
        const header = { token: await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getVariantDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/seller_catalogue_description/${this.state.catalogueID}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeleteImageVideo = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        let endPoint = `bx_block_catalogue/catalogues_variants/update_variant_image_video`
        const editCatalougeId = this.state.catalogueID
        const headers = {
            token: sellerLoginToken,
        };

        const formData = new FormData()
        let imageUpload = false;
        formData.append('catalogue_id', editCatalougeId);
        formData.append('catalogue_variant_color_id', this.state.variantID);
        if (this.state.imageRemoveArray.length > 0) {
            this.state.imageRemoveArray.forEach((image) => {
                formData.append(`catalogue_variant_images_attributes[][id]`, image);
                formData.append(`catalogue_variant_images_attributes[][_destroy]`, true + "");
            });
        }
        if (this.state.videoRemoveArray.length > 0) {
            this.state.videoRemoveArray.forEach((video) => {
                if (this.state.imageRemoveArray.length === 0) {
                    formData.append(`catalogue_variant_images_attributes`, 'false');
                }
                formData.append(`catalogue_variant_videos_attributes[][id]`, video);
                formData.append(`catalogue_variant_videos_attributes[][_destroy]`, true + "");
            });
        }
        this.state.uploadedImages.forEach((image: any,) => {
            if (!image.url) {
                imageUpload = false
                formData.append(`catalogue_variant_images_attributes[][image]`, image);
            } else { imageUpload = true }
        });
        this.state.uploadedVideos.forEach((video: any,) => {
            if (!video.url) {
                if (imageUpload) {
                    formData.append(`catalogue_variant_images_attributes`, 'false');
                }
                formData.append(`catalogue_variant_videos_attributes[][video]`, video);
            }
        });

        const imageDelete = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.imageDeleteId = imageDelete.messageId;

        imageDelete.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        imageDelete.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        imageDelete.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );

        imageDelete.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        runEngine.sendMessage(imageDelete.id, imageDelete);
    }

    getProductDetails=async(catalogueID:string)=>{      
        const singupLogin =  await getStorageData("singupLogin");
        const endPoint = `${configJSON.catalogueEndpoint}/${catalogueID}`
        const headers = {token: singupLogin};
        const getProductDetailsMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProductDetailsId = getProductDetailsMessage.messageId;
      
        getProductDetailsMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        getProductDetailsMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getProductDetailsMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getProductDetailsMessage.id, getProductDetailsMessage);
    
      }

    // Customizable Area End
}
