import { StoryMedia } from "../../blocks/postcreation/src/SellerFeedController.web";
import { UserMessageCreateParams, FileMessageCreateParams } from "@sendbird/chat/message";
import { GroupChannel } from "@sendbird/chat/groupChannel";

export const getFileFromUrl = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'defaultFileName';
    return new File([blob], fileName);
};

export const sendStoryImage = async (textMessage: string, channel: GroupChannel, media: StoryMedia) => {
    if (!channel) return;

    if (!media) return;

    let fileUrl = media.url;

    if (!fileUrl) return;
    try {
        const file = await getFileFromUrl(fileUrl);
        const fileMessageParams: FileMessageCreateParams = {
            file: file,
            customType: "image",
            data: JSON.stringify({ delivered: false, read: false, message: "" }),
        };

        channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const params = {
                    customType: 'image',
                    data: JSON.stringify({ delivered: true, read: false, message: "" }),
                };

                try {
                    await channel.updateFileMessage(sentMessage.messageId, params);
                    sendMessageSendBird(textMessage, channel);
                } catch (error) {
                    console.error(error);
                }

                sentMessage['data'] = JSON.stringify({ delivered: true, read: false, message: "" });
            }
        });
    } catch (error) {
        console.error("Error fetching the file:", error);
    }
};

export const sendMessageSendBird = async (textMessage: string, channel: GroupChannel) => {
    if (textMessage.trim() === '') return;
    try {
        const msgParams: UserMessageCreateParams = {
            message: textMessage,
            data: JSON.stringify({ delivered: false, read: false }),
        };
        channel?.sendUserMessage(msgParams).onSucceeded(async (sentMessage) => {
            if (sentMessage) {
                const sendbirdParams = {
                    customType: 'custom',
                    data: JSON.stringify({ delivered: true, read: false }),
                };
                try {
                    await channel.updateUserMessage(sentMessage.messageId, sendbirdParams);
                } catch (error) {
                }
                sentMessage['data'] = JSON.stringify({ delivered: true, read: false })
                // this.setState({ commentStoryId: {} as StoryAttributes })
            }
        });
    } catch (error) {
    }
};