import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require('react-navigation');
import React, { createRef } from "react";
import SendbirdChat, { FileCompat, SendbirdChatWith } from '@sendbird/chat';
import { GroupChannel, GroupChannelFilter, GroupChannelHandler, GroupChannelListOrder, GroupChannelModule } from "@sendbird/chat/groupChannel";
import { FileMessageCreateParams, UserMessage, UserMessageCreateParams } from "@sendbird/chat/message";
export const SENDBIRD_INFO = { appId: '43B06D36-77B1-4E08-861B-34A084B5B7DA' };
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import AttachmentIcon from '@material-ui/icons/Attachment';
import {Box} from "@material-ui/core";
export interface ChatMessage {
    sender: UserUpdateParams;
    message: string | null;
    time: string;
    imgUrl: string;
    userId: string | null;
    type: string | null;
    dateUpdated: Date;
    errors?: string;
    error?: string;
    groupDateLabel: string;
    createdAt:number;
}

interface EmojiType {
    id: string;
    name: string;
    unified: string;
    native: string;
    keywords: string[];
    custom: boolean;
    shortcodes: string[];
}

interface UserUpdateParams {
    nickname?: string;
    userId?: string;
}

export interface Receiver {
    userId: number;
    nickname: string;
    lastMessage: string;
    unseenMessageCount: number;
}

interface SendBirdCredential {
    id: number;
    user_id: string;
    nickname: string;
    token: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    access_token: string | null;
    token_expires_at: string | null;
    profile_url: string | null;
}

export interface Account {
    id: number;
    name: string;
    bio: string | null;
    user_name: string;
    profile_photo: string | null;
    sendbird_credential: SendBirdCredential;
}

export interface SellerDataResponse {
    accounts: Account[];
}

interface ApiCallPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}
  
interface ChannelApiResponse {
  channel_url: string;
}

export interface ErrorPayloadType {
  key: string
}

export interface ValidResponseType {
  data: object
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface SellerResponseType {
  channels: SellerPayload[];
}

export interface SellerPayload {
  channel_url: string;
  members: MemberPayload[];
}
export interface MemberPayload {
  nickname: string;
  profile_url: string;
  user_id: string;
} 
export interface ExtendedUserMessage extends UserMessage {
  bargainId: string;
    isDelivered?: boolean;
    isRead?: boolean;
    plainUrl?: string;
    name?:string;
}

export interface Seller {
  channelUrl: string;
  sellerName: string;
  sellerId: string;
  profileUrl: string;
  lastMessage?: string; 
  lastMessageTime?: number;
  messageType?: string;
  unreadCount?: number;
}
interface ChannelDataMap {
  lastMessage?: string; 
  lastMessageTime?: number; 
  messageType?: string; 
  unreadCount?: number 
}

interface BargainDataPayload{
  bargainId : string;
  messageId : number;
  productId : string;
  message : string;
  isAccept: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isCreateGroup: boolean;
    isDrawerOpen: boolean;
    messages: ExtendedUserMessage[];
    isEmoji: boolean;
    currrentEmojiPick: null | EmojiType;
    newMessage: string;
    hasMore: boolean;
    currentUserID: string;
    isLoading: boolean;
    channel: GroupChannel | null;
    messageInput: string;
    isOpen: boolean;
    sellerContactList : Account[];
    channelUrl: string;
    currentReceiverId: string | number;
    isTermsModal: boolean;
    sellerChatCreateId: string;
    isAppLoading: boolean;
    fileModal: boolean;
    imageFile: File | null;
    imageName: string;
    isFileUpload: boolean;
    fileUrl: string;
    dateLabel: string;
    isLoadingPrevMessages: boolean;
    currCharIndex:number;
    isImageViewModal: boolean;
    imageViewUrl: string;
    sellerList: Seller[];
    selectedSeller: Seller | null;
    isSellerOnline: boolean;
    isFromRefresh: boolean;
    isFromCreateChannel: boolean;
    isCounterModalOpen: boolean;
    counterValue: string | number;
    counterValueError: string;
    bargainData: BargainDataPayload | null;
    isDeclineModalOpen: boolean;
    rejectionReason: string;
    rejectionReasonError: boolean;
    rejectionOtherReason: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ChatControllerWeb extends BlockComponent<Props,S,SS> {
    // Customizable Area Start
    chatContainerRef: React.RefObject<HTMLDivElement> = createRef();
    fileInputRef: React.RefObject<HTMLInputElement> = createRef();
    sendBird: SendbirdChatWith<GroupChannelModule[]> | null;
    private debounceTimeout: NodeJS.Timeout | null = null;
    pickerRef: React.RefObject<HTMLDivElement> = React.createRef();
    messageStatusTimer: ReturnType<typeof setTimeout> | number = 0;
    onlineCheck: ReturnType<typeof setInterval> | number = 0;
    getAllSellerContactList: string = "";
    getCreateChatChannels: string = "";
    getAllUserChannelData: string = "";
    postBargainCounterOffersApiCallID: string = "";
    postOrderAcceptApiCallID: string = "";
    postOrderDeclineApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isCreateGroup: false,
            isDrawerOpen: false,
            messages:[],
            isEmoji: false,
            currrentEmojiPick: null,
            newMessage: "",
            hasMore: false,
            currentUserID: "",
            isLoading: false,
            channel: null,
            messageInput: "",
            isOpen: false,
            sellerContactList: [],
            channelUrl: "",
            currentReceiverId : "",
            isTermsModal: false,
            sellerChatCreateId: "",
            isAppLoading: false,
            fileModal: false,
            imageFile: null,
            imageName: "",
            isFileUpload: false,
            fileUrl: "",
            dateLabel: "Today",
            isLoadingPrevMessages: false,
            currCharIndex:0,
            isImageViewModal: false,
            imageViewUrl: "",
            sellerList: [],
            selectedSeller: null,
            isSellerOnline: false,
            isFromRefresh: false,
            isFromCreateChannel: false,
            isCounterModalOpen: false,
            counterValue: "",
            counterValueError: "",
            bargainData: null,
            isDeclineModalOpen: false,
            rejectionReason: "Overly Complex Requests",
            rejectionReasonError: false,
            rejectionOtherReason: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.sendBird = null;
        this.messageStatusTimer = 0;
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJson.status === 500) {
                this.showAlert("Error", "Internal Server Error");
                this.setState({ isAppLoading: false });
                return;
            }
            if (responseJson && !responseJson.errors) {
              switch (apiRequestCallId) {
                  case this.getAllSellerContactList:
                      this.getSellerListSuccessCallBack(responseJson);
                      break;
          
                  case this.getCreateChatChannels:
                      this.getCreateChannelSuccessCallBack(responseJson);
                      break;
          
                  case this.getAllUserChannelData:
                      this.getAllChannelDataSuccessCallBack(responseJson);
                      break;
          
                  case this.postBargainCounterOffersApiCallID:
                      this.postMakeCounterApiSuccessCallBack(responseJson);
                      break;

                  case this.postOrderAcceptApiCallID:
                      this.postBargainAcceptSuccesCallBack(responseJson);
                      break;
                  
                  case this.postOrderDeclineApiCallID:
                    this.postBargainDeclineSuccessCallBack(responseJson);
                    break;
          
                  default:
                      break;
              }
          }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
       this.setState({isAppLoading: true});
       let buyerAuthToken = await getStorageData("authToken");
       if(!buyerAuthToken){
         this.handleNavigateToLogin();
        }
        await this.getAllContactList();
        const buyerData = await getStorageData("Buyer_Data");
        const buyerObj = JSON.parse(buyerData);
        let buyerId = await buyerObj?.sendbird_credential?.user_id;
        let buyerToken = await buyerObj?.sendbird_credential?.access_token;
        this.initializeSendBirdSDK(buyerId, buyerToken);
        this.scrollToBottom();
        document.addEventListener("mousedown", this.handleClickOutside);
    };

    prepareSellersList = async (response: SellerResponseType) => {      
      let sellerSet: Seller[] = [];
      response.channels.forEach((channelPayload) => {
        sellerSet.push({
          channelUrl: channelPayload.channel_url,
          sellerName: channelPayload.members[0]?.nickname  || "",
          sellerId: channelPayload.members[0]?.user_id || "",
          profileUrl: channelPayload.members[0]?.profile_url || "",
        });
      });
      const grpChannelFilter = new GroupChannelFilter();
      grpChannelFilter.includeEmpty = true;
      const collections = this.sendBird?.groupChannel.createGroupChannelCollection({
        filter: grpChannelFilter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      });
      const channels = await collections?.loadMore();
      if (!channels) {
        return;
      }
      const channelDataMap = new Map<string, { lastMessage?: string; lastMessageTime?: number; messageType?: string; unreadCount?: number }>();
      for (const channelData of channels) {
        const channelDetails = await this.getChannelDetails(channelData);
        channelDataMap.set(channelData.url, channelDetails);
      }; 
      this.updateSellerSetHandle(sellerSet, channelDataMap);
    };

    getChannelDetails = async (channelData : GroupChannel) =>{
      let lastMessageTime = 0;
      let messageType = '';
      let lastMessage = '';
      const messages = await channelData.getMessagesByTimestamp(Date.now(), {
        prevResultSize: 50,
        isInclusive: false,
        nextResultSize: 0,
      });

      let unreadCount = 0;
      for (const message of messages) {
        const userMsgs = message as UserMessage;
        if ( userMsgs.customType === 'read_all_receipt' || userMsgs.customType === 'read_receipt' || userMsgs.customType === 'online_receipt' ) {
            continue;
        }

        if (userMsgs.createdAt > lastMessageTime) {
          messageType = userMsgs.customType || 'custom';
          lastMessageTime = userMsgs.createdAt;
          lastMessage = this.isLastOnOrder(userMsgs)
        }

        if (userMsgs.data) {
          const messageData = JSON.parse(userMsgs.data);
          if (messageData.read === false && userMsgs.sender.userId !== this.state.currentUserID) {
            unreadCount++;
          }
        }
      }
      return { lastMessage, lastMessageTime, messageType, unreadCount };
    };

    isLastOnOrder = (userMsgs: UserMessage) => {
      if (userMsgs.customType === "on_order") {
        return "On Order Request";
      } else if (userMsgs.customType === "bargain" || userMsgs.customType === 'bargain_accept' || userMsgs.customType === 'bargain_declined') {
        return "Bargain Request";
      } else {
        return userMsgs.message;
      }
    };

    updateSellerSetHandle = (sellerSet: Seller[], channelDataMap: Map<string, ChannelDataMap>) => {
      const updatedSellerSet = sellerSet.map((seller) => {
        const channelDetails = channelDataMap.get(seller.channelUrl) || {};
        return {
          ...seller,
          lastMessageTime: channelDetails.lastMessageTime,
          lastMessage: channelDetails.lastMessage,
          unreadCount: channelDetails.unreadCount,
          messageType: channelDetails.messageType,
        };
      });
      updatedSellerSet.sort((a, b) => (b.lastMessageTime || 0) - (a.lastMessageTime || 0));      
      this.setState({ sellerList: updatedSellerSet }, async () => {
        if (!this.state.isFromRefresh && !this.state.isFromCreateChannel) {
          this.setState({ selectedSeller: updatedSellerSet[0], isAppLoading: false, channelUrl: updatedSellerSet[0]?.channelUrl });
          await this.loadChannels(this.state.sellerList[0].channelUrl);
        } else if (this.state.isFromRefresh) {
          let channelIdx = this.state.sellerList.findIndex((sellerData) => sellerData.channelUrl === this.state.channelUrl);
          this.setState({ selectedSeller: updatedSellerSet[channelIdx], isFromRefresh: false, isAppLoading: false, });
        } else if (this.state.isFromCreateChannel) {
          let createChannelIdx = this.state.sellerList.findIndex((sellerData) => sellerData.channelUrl === this.state.channelUrl);
          this.setState({ selectedSeller: updatedSellerSet[createChannelIdx], isFromCreateChannel: false, isAppLoading: false }, async () => {
            await this.loadChannels(this.state.channelUrl || "");
          });
        }
        setTimeout(() => {
          if (this.chatContainerRef.current) {
            this.chatContainerRef.current.addEventListener('scroll', this.handleScroll);
          }
        },1000);
      });
    };


    async componentWillUnmount() {
      clearInterval(Number(this.onlineCheck));
        if (this.chatContainerRef.current) {
            this.chatContainerRef.current.removeEventListener('scroll', this.handleScroll);
        }
    };

    handleScroll = () => {
        if (this.chatContainerRef.current) {
            if (this.chatContainerRef.current.scrollTop === 0 && this.state.hasMore && !this.state.isLoading) {
                this.loadMessages(true);
            }
            this.handleDateLabel();
        }
    };

    handleDateLabel = () => {
        if (!this.chatContainerRef.current) return;
        const { messages } = this.state;
        const scrollTop = this.chatContainerRef.current.scrollTop;
        const messageElements = this.chatContainerRef.current.children;
        for (let i = 0; i < messageElements.length; i++) {
            const element = messageElements[i] as HTMLElement;
            if (element.offsetTop > scrollTop) {
                this.setState({ dateLabel: this.formatDate(messages[i].createdAt) });
                break;
            }
        }
    };

    handleClickOutside = (event: MouseEvent) => {
        if (this.pickerRef.current && !this.pickerRef.current.contains(event.target as Node)) {
            this.setState({ isEmoji: false });
        }
    };

    initializeSendBirdSDK = async (id: string, token: string) => {
        this.setState({ currentUserID: id });
        const sendbirdChat = SendbirdChat.init({
            appId: SENDBIRD_INFO.appId,
            localCacheEnabled: true,
            modules: [new GroupChannelModule()]
        });
        try {
           await sendbirdChat.connect(id,token);
        } catch (error) {
        }
        await sendbirdChat.setChannelInvitationPreference(true);
        this.sendBird = sendbirdChat;
        await this.getBuyerChannels();
    };

    loadChannels = async (channelURL : string) => {
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true;
        const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
            filter: groupChannelFilter,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });
        const channels = await collection?.loadMore();
        if (channels && channels.length > 0) {
          let channelIndex = channels.findIndex((channel) => channel.url === channelURL);
          this.setState({ channel: channels[channelIndex] },async () => {
            await this.setupChannelHandler();
            this.sendSellerOnlineStatus();
            this.onlineCheck = setInterval(this.sendSellerOnlineStatus, 20000);
              await this.loadMessages();
          });
        }
    };

    setupChannelHandler = async () => {
        if (this.sendBird) {
            const channelHandler = new GroupChannelHandler({
                onMessageReceived: (channel, message) => {
                  this.refreshSellerList();
                    if (channel.url === this.state.channel?.url) {
                        if (message.customType === 'read_receipt') {
                          const data = JSON.parse(message.data);
                          this.updateMessageReadStatus(data.readMessageId, true);
                        } else if (message.customType === 'read_all_receipt') {
                          this.updateMessageAllReadStatus(true);
                        } else if (message.customType === 'online_receipt') {
                          this.setState({ isSellerOnline: true });
                          this.clearOnlineSellerStatus();
                        } else {
                          this.markMessageAsRead(message as ExtendedUserMessage);
                          this.setState((prevState) => ({
                            messages: [...prevState.messages, message as ExtendedUserMessage]
                          }), this.scrollToBottom);
                        }
                      }
                }
            });
            this.sendBird.groupChannel.addGroupChannelHandler('CHANNEL_HANDLER_ID', channelHandler);
        }
    };

    refreshSellerList = () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.setState({isFromRefresh : true})
        this.getBuyerChannels();
      }, 500);
    };
  

    clearOnlineSellerStatus = () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.setState({ isSellerOnline: false });
      }, 21000);
    };

    sendSellerOnlineStatus = async () => {
      const { channel } = this.state;
      if (!channel) return;
      const param: UserMessageCreateParams = {
        customType: 'online_receipt',
        message: `I am online.`,
      };
      try {
        channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
          await channel.deleteMessage(sentMessage);
        });
      } catch (error) {
      }
    }

    loadMessages = async (isFromPagination = false) => {
        if (!this.state.channel) return;
        this.setState({ isLoading: true });
        const messagesContainer = this.chatContainerRef.current;
        if (messagesContainer) {
          const currentScrollHeight = messagesContainer.scrollHeight;
          this.setState({ isLoading: true });
          try {
            const oldestMessage = this.state.messages[0];
            const timestamp = oldestMessage ? oldestMessage.createdAt : Date.now();
            const messages: ExtendedUserMessage[] = await this.state.channel.getMessagesByTimestamp(
              timestamp,
              { isInclusive: false, prevResultSize: 50, nextResultSize: 0 }
            ) as ExtendedUserMessage[];

            this.deleteUnnecessaryMessages(messages);
            const filteredMessages = messages.filter((message: ExtendedUserMessage) => message.customType !== 'read_receipt' && message.customType !== 'read_all_receipt' && message.customType !== 'online_receipt' &&  message.data !== '');

            this.setState((prevState) => ({
              messages:  [...filteredMessages, ...this.state.messages] ,
              isLoading: false,
              hasMore: messages.length > 0,
              isLoadingPrevMessages: false
            }), () => {
              const newScrollHeight = messagesContainer.scrollHeight;
              messagesContainer.scrollTop = newScrollHeight - currentScrollHeight;
              let lastMessage = this.state.messages[this.state.messages.length - 1];
              this.checkLastMessage(lastMessage);
              this.updateMessageAllReadStatus(false);
              !isFromPagination && this.scrollToBottom();
            });
          } catch (error) {
            this.setState({ isLoading: false });
          }
        }
    };

    checkLastMessage = (lastMessage :ExtendedUserMessage) =>{
      if (lastMessage && lastMessage?.data && lastMessage.sender.userId !== this.state.currentUserID) {
        let chatData = JSON.parse(lastMessage.data);
        if (!chatData.read) {
          this.markAllMessageAsRead();
        }
      }
    };

    deleteUnnecessaryMessages = async (messages: ExtendedUserMessage[]) => {
        const { channel } = this.state;
        if (!channel) return;
    
        const unnecessaryMessages = messages.filter(
          (message) => message.customType === 'read_receipt' || message.customType === 'read_all_receipt' || message.customType === 'online_receipt'
        );
        for (const message of unnecessaryMessages) {
          if (message.sender.userId === this.state.currentUserID) {
            await channel.deleteMessage(message);
            await this.delay(100);
          }
        }
    };


    markAllMessageAsRead = async () => {
        const { channel } = this.state;
        if (!channel) return;
        const params: UserMessageCreateParams = {
          message: `Read all messages`,
          customType: 'read_all_receipt',
        };
        try {
          channel.sendUserMessage(params);
        } catch (error) {
        }
    };

    markMessageAsRead = async (message: ExtendedUserMessage) => {
        const { channel } = this.state;
        if (!channel || !message) return;
        const params: UserMessageCreateParams = {
          message: `Read receipt for message ID: ${message.messageId}`,
          customType: 'read_receipt',
          data: JSON.stringify({ readMessageId: message.messageId }),
        };
        try {
          channel.sendUserMessage(params);
        } catch (error) {
        }
    };
    
    updateMessageReadStatus = async (messageId: number, isRead = false) => {
        const { channel } = this.state;
        if (!channel) return;
        this.state.messages.forEach(async (message) => {
          if (message.messageId === messageId) {
            const params = {
              customType: message.customType,
              data: JSON.stringify({ delivered: true, read: true }),
            };
            try {
              if(message.customType === 'audio' || message.customType === 'image') {
                let payload = JSON.parse(message.data);
                if(message.customType === 'image') {
                  params['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
                }
                await channel.updateFileMessage(message.messageId, params);
              } else  {
                await channel.updateUserMessage(messageId, params);
              }
            } catch (error) {
            }
          }
        });
        this.buyerUpdateMessage(messageId, isRead)
    };

  buyerUpdateMessage = (messageId: number, isRead: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.messageId === messageId) {
        const params = {
          customType: message.customType,
          data: JSON.stringify({ delivered: true, read: true }),
        };
        if (message.customType === 'image') {
          let payload = JSON.parse(message.data);
          params['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
        }
        const newMessage = {
          ...message,
          isRead: isRead || message.isRead,
          data: params.data,
        };
        return newMessage;
      }
      return message;
    });
    this.setState({ messages: updatedMessages as ExtendedUserMessage[] });
  };
    
    delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    
    updateMessageAllReadStatus = async (isReadAll = false) => {
      const { channel } = this.state;
      if (!channel) return;
      try {
        for (const message of this.state.messages) {
          const params = {
            customType: message.customType,
            data: JSON.stringify({ delivered: true, read: true }),
          };
          if (message.sender.userId !== this.state.currentUserID) {
            if(message.customType === 'audio' || message.customType === 'image' || message.customType === 'pdf' || message.customType === 'doc') {
              let payload = JSON.parse(message.data);
              if(message.customType === 'image') {
                params['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
              } else {
                params['data'] = JSON.stringify({ delivered: true, read: true, message: "", waveData: payload.waveData, duration: payload.duration });
              }
              await channel.updateFileMessage(message.messageId, params);
            } else  {
              await channel.updateUserMessage(message.messageId, params);
            }
            await this.delay(300);
          }
        }
      } catch (error) {
      }
      this.updatedMessagesParam(isReadAll);
    };

  updatedMessagesParam = (isReadAll: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      const params = {
        customType: message.customType,
        data: JSON.stringify({ delivered: true, read: true }),
      };
      if (message.customType === 'audio' || message.customType === 'image' || message.customType === 'pdf' || message.customType === 'doc') {
        let payload = JSON.parse(message.data);
        if (message.customType === 'image') {
          params['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
        } else {
          params['data'] = JSON.stringify({ delivered: true, read: true, message: "", waveData: payload.waveData, duration: payload.duration });
        }
      }
      const newMessage = {
        ...message,
        isRead: message.isRead,
        data: isReadAll ? params.data : message.data,
      };
      return newMessage;
    });
    this.setState({ messages: updatedMessages as ExtendedUserMessage[] });
  };

    sendMessageSendBird = async () => {
        const { newMessage, channel } = this.state;
        if (newMessage.trim() === '') return;
        try {
            const param: UserMessageCreateParams = {
                message: newMessage,
                data: JSON.stringify({ delivered: false, read: false }),
            };
            channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
                if (sentMessage) {
                    const params = {
                      customType: 'custom',
                      data: JSON.stringify({ delivered: true, read: false }),
                    };
                    try {
                      await channel.updateUserMessage(sentMessage.messageId, params);
                      this.refreshSellerList();
                    } catch (error) {
                    }
                    sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
                    this.setState((prevState) => ({
                      newMessage: '',
                      messages: [...prevState.messages, sentMessage as ExtendedUserMessage],
                    }), this.scrollToBottom);
                  }
            });
        } catch (error) {
        }
    };

    scrollToBottom() {
        if (this.chatContainerRef.current) {
            this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
        }
    };

    getSellerListSuccessCallBack = (responseJson: SellerDataResponse) => {
       this.setState({sellerContactList: responseJson.accounts});
    };

    getCreateChannelSuccessCallBack = async (responseJson: ChannelApiResponse) => {
      this.setState({ channelUrl: responseJson?.channel_url, messages: [], isCreateGroup: !this.state.isCreateGroup, isFromCreateChannel: true }, async () => {
        await this.getBuyerChannels();
        this.getAllContactList();
      });
    };

    getAllChannelDataSuccessCallBack = async (responseJson : SellerResponseType) => {
      await this.prepareSellersList(responseJson);
    };

    getUserChannelsApiCall = async () => {
        let token = await getStorageData("buerLoginToken");
        const buyerData = await getStorageData("Buyer_Data");
        const buyerObj = JSON.parse(buyerData)
        const buyerId = await buyerObj?.sendbird_credential?.user_id;
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };
        const requestMessageContact = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getChannelData}?user_id=${buyerId}`
        );
        runEngine.sendMessage(requestMessageContact.id, requestMessageContact);
        return requestMessageContact.messageId;
    };

    getSellerContactApiCall = async () => {
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };
        const requestMessageContact = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessageContact.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBuyerContactEndpoint
        );
        runEngine.sendMessage(requestMessageContact.id, requestMessageContact);
        return requestMessageContact.messageId;
    };

    getCreatedChatChannels = async (friendId: string, buyerId: string) => {
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };
        const body = {
            channel_type: "dm",
            user_ids: [buyerId, friendId],
            name: "openChannel",
            data: ""
        };
        const requestMessageChatChannels = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageChatChannels.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageChatChannels.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessageChatChannels.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateChannelEndPoint
        );

        requestMessageChatChannels.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(requestMessageChatChannels.id, requestMessageChatChannels);
        return requestMessageChatChannels.messageId;
    };

    handleCreateChannelApi = async () =>{
        let buyerData = await getStorageData("Buyer_Data");
        const buyerObj = JSON.parse(buyerData);
        let buyerId = buyerObj.account.id.toString();
        this.setState({isAppLoading: true});
        this.getCreateChatChannels = await this.getCreatedChatChannels(this.state.sellerChatCreateId,buyerId);
    };

    getAllContactList = async () => {
        this.getAllSellerContactList = await this.getSellerContactApiCall();
    };
    
    getBuyerChannels = async () => {
        this.getAllUserChannelData = await this.getUserChannelsApiCall();
    };

    handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newMessage: event.target.value });
    };

    handleKeyPress = () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.sendMessage();
      }, 300);
    };
        
    handleCreateGroupToggle = () => {
        this.setState({ isCreateGroup: !this.state.isCreateGroup });
    };

    handleChatDrawerToggle = () => {
        this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
    };

    handleFileChange = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    };

    handleEmojiPicker = () =>{
        this.setState({isEmoji: !this.state.isEmoji});
    };
    
    handleEmojiSelect = (emoji: EmojiType) => {
      this.setState({ newMessage: this.state.newMessage + emoji?.native, isEmoji: !this.state.isEmoji});
    };

    handleTermsConditionModal = (friendId: string) => {
        this.setState({ isTermsModal: true, sellerChatCreateId: friendId });
    };

    handleTermsModalToggle = () => {
        this.setState({ isTermsModal: !this.state.isTermsModal });
    };

    handleAgree = () => {
        this.setState({ isTermsModal: false });
        this.handleCreateChannelApi();
    };

    handleClickSetChannelUser = (seller: Seller, chatIndex: number) => {
        this.setState({ selectedSeller: seller, messages: [], isSellerOnline: false, currCharIndex: chatIndex, channelUrl: seller.channelUrl }, () => {
          if(this.state.selectedSeller){
            this.state.selectedSeller['unreadCount'] = 0;
          }
          this.loadChannels(this.state.selectedSeller?.channelUrl || '');
        });
    };

    handleNavigateToLogin = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    };


    truncatedLastMessage = (lastMessage: string) => {
        if (lastMessage.length <= 16) {
            return lastMessage;
        }
        return lastMessage.substring(0, 16) + ' ...';
    };

    truncatedDocumentName = (docName: string) => {
        if (docName.length <= 21) {
            return docName;
        }
        return docName.substring(0, 21) + ' ...';
    };

    handleInputFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) return;
        this.setState({ 
          imageName: event.target.files[0].name,
          imageFile: event.target.files[0]
        });
    
        if (event.target.value && event.target.files) {
          const file = event.target.files[0] as Blob;
          let reader = new FileReader();
          reader.onload = (readerEvent) => {
            this.setState({ fileUrl: readerEvent.target?.result as string });
          };
          reader.readAsDataURL(file);
          this.setState({ fileModal: true, isFileUpload : true });
          event.target.value = "";
        }
        else {
          this.setState({ fileUrl: "" })
        }
    };

    sendMessage = async () => {
        if (!this.state.isFileUpload) {
          if (this.state.newMessage.trim() !== "") {
            this.sendMessageSendBird();
          }
        } else {
          this.handleFileSend();
        }
    };

    handleFileSend = async () => {
        const { channel } = this.state;
        if (!channel) return;
    
        const fileMessageParams: FileMessageCreateParams = {
          file: this.state.imageFile as FileCompat,
          customType: this.checkFileType(),
          data: JSON.stringify({ delivered: false, read: false, message: this.state.newMessage }),
        }
        channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
          if (sentMessage) {
            const params = {
              customType: this.checkFileType(),
              data: JSON.stringify({ delivered: true, read: false, message: this.state.newMessage }),
            };
    
            try {
              await channel.updateFileMessage(sentMessage.messageId, params);
            } catch (error) {
            }
            sentMessage['data'] = JSON.stringify({ delivered: true, read: false, message: this.state.newMessage });
            this.setState((prevState) => ({
              messages: [...prevState.messages, sentMessage as ExtendedUserMessage],
              newMessage: '',
              fileModal: false,
              fileUrl: '',
              isFileUpload: false
         }), this.scrollToBottom);
        }
      });
    };

    getAuthenticatedUrl = (plainUrl: string) => {
       return plainUrl + '?auth=' + this.sendBird?.ekey;
    };
    
    checkFileType = () =>{
        if(this.state?.imageFile){
            switch (this.state.imageFile.type) {
                case configJSON.applicationPdf:
                    return configJSON.pdfText;
                case configJSON.applicationDoc:
                    return configJSON.docText;
                case configJSON.applicationDocx:
                    return configJSON.docxText; 
                default:
                    return configJSON.imageText;
            }
        }
    }

    handleDocumentFileOpen = (fileUrl : string) =>{
        if (fileUrl.includes('.docx') || fileUrl.includes('.doc')) {
            const docUrl = `http://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`;
            const newTab = window.open(docUrl,"_blank");
            newTab?.focus();
        } else {
            const newWidnow = window.open(fileUrl, "_blank");
            newWidnow?.focus();
        }
    };

    formatDate = (dateInMillis: number = Date.now()): string => {
        const now = moment();
        const messageDate = moment(dateInMillis);
        if (now.isSame(messageDate, 'day')) {
          return 'Today';
        } else if (now.subtract(1, 'days').isSame(messageDate, 'day')) {
          return 'Yesterday';
        } else {
          return messageDate.format('MMMM D, YYYY');
        }
    };  

  handleImageViewModalOpen = (imgUrl: string) => {
    this.setState({ imageViewUrl: imgUrl, isImageViewModal: !this.state.isImageViewModal })
  };

  handleImageModalClose = () =>{
    this.setState({isImageViewModal: false })
  };

  checkLastMessageType = (chatListData: Seller) => {
    if (chatListData?.lastMessage !== null && chatListData?.lastMessage) {
      return this.truncatedLastMessage(chatListData?.lastMessage)
    } else if (chatListData?.lastMessage === undefined && chatListData.lastMessageTime !== 0) {
      return (
        <Box className="attachmentLastMessage"><AttachmentIcon className="mediaIcons" /> <span>{configJSON.attachmentText}</span></Box>
      )
    }
  };

  checkMessageType = (chat: ExtendedUserMessage) => {
    const excludedTypes = ['on_order', 'bargain', 'bargain_accept', 'bargain_declined', 'doc', 'docx', 'pdf', 'image'];
    if (chat.message && !excludedTypes.includes(chat.customType)) {
      return true;
    } else {
      return false;
    }
  }; 

  formattedChatDate = (chat: ExtendedUserMessage) => {
    return moment(chat.createdAt).format('hh:mm');
  }

  handleToggleCounterModal = () => {
    this.setState({ isCounterModalOpen: !this.state.isCounterModalOpen })
  };

  handleCounterValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = configJSON.offerPriceRegex;
    if (regex.test(value)) {
      this.setState({ counterValue: value });
    }
  };

  handleOfferModalOpen = (bargainId:string, messageId: number, productId: string, message: string, isAccept: boolean) => {
    let data = {
      messageId,
      productId,
      message,
      isAccept,
      bargainId
    };
    this.setState({ bargainData: data, isCounterModalOpen: true });
  };

  apiCall = async (apiReqData: ApiCallPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    let buyerToken = await getStorageData("buerLoginToken");
    const header = {
      "Content-Type": contentType,
      token: buyerToken,
    };
    const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  };

  handleCounterOfferSubmit = async () => {
    const { bargainData, counterValue } = this.state;
    if (this.state.counterValue === '' || Number(this.state.counterValue) === 0) {
      this.setState({ counterValueError: "Please enter a valid offer price" });
      return;
    }
    if (bargainData) {
      let bargainProduct = JSON.parse(String(bargainData?.message));
      this.postBargainCounterOffersApiCallID = await this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.postApiMethod,
        endPoint: configJSON.postBargainOfferApiEndPoint,
        body: {
          "data": {
            "catalogue_id": Number(bargainProduct.productId),
            "ask_price": Number(counterValue),
            "comments": "I would like to negotiate the price."
          }
        },
      });
    }
  };

  postMakeCounterApiSuccessCallBack = (responseJSON: ChannelApiResponse) => {
    if (responseJSON.channel_url) {
      this.handleOfferSend();
    }
  };

  handleOfferSend = async () => {
    const { channel, bargainData } = this.state;
    if (!channel) return;
    try {
      if (bargainData) {
        let messageData = JSON.parse(String(bargainData?.message));
        messageData.lastPrice = messageData.askPrice;
        messageData.askPrice = this.state.counterValue;
        const param: UserMessageCreateParams = {
          message: JSON.stringify(messageData),
          data: JSON.stringify({ delivered: false, read: false }),
          customType: 'bargain',
        };
        channel?.sendUserMessage(param).onSucceeded(async (sendOfferMsg) => {
          if (sendOfferMsg) {
            const params = {
              customType: 'bargain',
              data: JSON.stringify({ delivered: true, read: false }),
            };
            try {
              await channel.updateUserMessage(sendOfferMsg.messageId, params);
              this.refreshSellerList();
            } catch (error) {
            }
            sendOfferMsg['data'] = JSON.stringify({ delivered: true, read: false });
            this.setState((prevState) => ({
              messages: [...prevState.messages, sendOfferMsg as ExtendedUserMessage],
              newMessage: '',
            }), this.scrollToBottom);
          }
        });
        this.handleOrderStatusUpdateBuyer(true);
        this.setState({ isCounterModalOpen: false, counterValue: "" });
      }
    } catch (error) {
    }
  };


  handleAcceptBargainOffer = (bargainId:string, messageId: number, productId: string, message: string, isAccept: boolean) => {
    let bargainObj = {
      bargainId,
      messageId,
      productId,
      message,
      isAccept,
    };
    this.setState({ bargainData: bargainObj }, () => {
      this.postAcceptOrderApiCall();
    });
  };

  handleDeclineBargainOffer = (bargainId:string, messageId: number, productId: string, message: string, isAccept: boolean) => {
    let bargainObj = {
      bargainId,
      productId,
      messageId,
      isAccept,
      message,
    };
    this.setState({ bargainData: bargainObj, isDeclineModalOpen: true });
  };


  postAcceptOrderApiCall = async () => {
    const { bargainData } = this.state;    
    if (bargainData) {
      this.setState({ isAppLoading: true });
      let acceptData = JSON.parse(String(bargainData?.message));
      this.postOrderAcceptApiCallID = await this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.postApiMethod,
        endPoint: configJSON.postOrderAcceptApiEndPoint,
        body: {
          "bargain_request": "true",
          "final_price": acceptData.askPrice,
          "request_id": Number(bargainData.bargainId)
        }
      });
    }
  };

  postDeclineOrderApiCall = async () => {
    const { bargainData, rejectionReason, rejectionOtherReason } = this.state;
    if (bargainData) {
      if (!(bargainData.isAccept) && (this.state.rejectionReason === "Other" && !(this.state.rejectionOtherReason.trim()))) {
        this.setState({ rejectionReasonError: true });
        return;
      }
      this.setState({ isAppLoading: true });
      let declineData = JSON.parse(String(bargainData?.message));
      this.postOrderDeclineApiCallID = await this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.postApiMethod,
        endPoint: configJSON.postOrderRejectApiEndPoint + `?request_id=${Number(declineData.bargainId)}&bargain_request=true`,
        body: {
          id: parseInt(declineData.bargainId),
          cancellation_reason: rejectionReason === "Other" ? rejectionOtherReason : rejectionReason
        },
      });
    }
  };

  postBargainAcceptSuccesCallBack = (responseJson: { requests: { bargain_status: string, catalogue_id: string } }) => {
    this.setState({ isAppLoading: false });
    if (responseJson.requests.bargain_status === 'accepted') {
      this.handleOrderStatusUpdateBuyer(false);
    }
  };

  postBargainDeclineSuccessCallBack = (responseJson: { requests: { bargain_status: string, catalogue_id: string } }) => {
    this.setState({ isAppLoading: false, isDeclineModalOpen: false });
    if (responseJson.requests.bargain_status === 'rejected') {
      this.handleOrderStatusUpdateBuyer(false);
    }
  };

  handleOrderStatusUpdateBuyer = async (isFromMakeOffer = false) => {
    const { channel, bargainData } = this.state;
    if (!channel) return;
    try {
      if(bargainData){
      let messageData = JSON.parse(String(bargainData.message));
      messageData.bargainStatus = bargainData?.isAccept ? "Accepted" : "Declined";
      const params = {
        message: JSON.stringify(messageData),
        data: JSON.stringify({ delivered: true, read: true }),
        customType: "bargain",
      };
      const updatedMsgs = this.state.messages.map((message) => {
        if (message.messageId === bargainData?.messageId) {
          message.message = JSON.stringify(messageData);
          return { ...message };
        } 
        return message;
      });
      await channel.updateUserMessage(Number(bargainData?.messageId), params);
      this.setState({ messages: updatedMsgs as ExtendedUserMessage[], rejectionOtherReason: '', rejectionReason: 'Overly Complex Requests' });
      if(!isFromMakeOffer){
        this.sendOrderStatusMessage(bargainData.isAccept);
      }
    }
    } catch (error) {
    }
  };

  sendOrderStatusMessage = async (isOrderSuccess: boolean) => {
    const { channel, bargainData } = this.state;
    try {
      if(bargainData){
      let messageData = JSON.parse(String(bargainData.message));
      const params: UserMessageCreateParams = {
        message: JSON.stringify(messageData),
        data: JSON.stringify({ delivered: false, read: false }),
        customType: isOrderSuccess ? 'bargain_accept' : 'bargain_declined',
      };
      channel?.sendUserMessage(params).onSucceeded(async (orderStatusMsg) => {
        if (orderStatusMsg) {
          const params = {
            customType: isOrderSuccess ? 'bargain_accept' : 'bargain_declined',
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateUserMessage(orderStatusMsg.messageId, params);
            this.refreshSellerList();
          } catch (error) {
          }
          orderStatusMsg['data'] = JSON.stringify({ delivered: true, read: false });
          this.setState((prevState) => ({
            messages: [...prevState.messages, orderStatusMsg as ExtendedUserMessage],
            newMessage: '',
          }), () => {
            this.scrollToBottom();
          }
          );
        }
      });
     }
    } catch (error) {
    }
  };

  navigateToCart = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerMultipleCart");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleToggleDeclineModal = () => {
    this.setState({ isDeclineModalOpen: !this.state.isDeclineModalOpen });
  };

  handleRejectReasonChange= (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ rejectionReason: event?.target.value });
  };

  handleOtherReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const wordCnt = this.getInputWordCount(inputValue);    
    if(wordCnt < 100){
      this.setState({rejectionOtherReason : event?.target.value, rejectionReasonError: false});
    }
  };

  getInputWordCount = (inputText: string) => {
    return inputText.trim().split(/\s+/).length;
  };
    // Customizable Area End
}